<template>
  <WcModal class="" @backClick="backClick">
    <div class="wc-modal">
      <slot></slot>

      <WcButton @btnClick="confirmClicked">確認</WcButton>
    </div>
  </WcModal>
</template>

<script>
import WcModal from '@/components/commons/WcModal.vue';
import WcButton from '@/components/commons/WcButton.vue';

export default {
  name: 'WcAlertModal',
  data() {
    return {
    }
  },
  components: {
    WcModal,
    WcButton,
  },
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    hideOk: {
      type: Boolean,
      default: false,
    },
    disableOk: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    headerClass: {
      type: String,
      default: '',
    }
  },
  methods: {
    backClick() {
      this.$emit('backClick');
    },
    confirmClicked() {
      this.$emit('ok');
    },
  }

}
</script>

<style scoped>

  .wc-modal {
    border-radius: .5rem;
    overflow: hidden;
    min-width: 300px;
    background-color: #fff;
    /* box-shadow: 0 0 8px 0 #ffffff78; */
    max-width: 90vw;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .wc-modal .header {
    position: relative;
    background-color: #4D4D4D;
    color: #fff;
    padding: 18px 1rem 14px;
    flex: 0 0 auto;
  }

  .wc-modal .header .title {
    text-align: center;
    margin: 0;
    font-size: 14px;
  }

  .wc-modal .header .modal-close {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .wc-modal .content {
    padding: 1rem 1.5rem;
    flex: 1 1;
    overflow-y: auto;
  }

  .wc-modal .footer {
    display: flex;
  }

  .wc-modal .footer>button {
    font-size: 14px;
    display: block;
    padding: 12px;
    border: solid 1px #fff;
    background-color: #EEEEEE;
    color: #000;
    flex: 1 1;
    text-align: center;
  }

  .wc-modal .footer>button:disabled {
    color: #aaa;
  }

  .wc-modal .footer>a:hover {
    text-decoration: none;
  }
</style>
