<template>
  <div class="real-time-map">
    <l-map v-if="showMap" style="height: 100%" :zoom="zoom" :center="center" @update:zoom="updateZoom" @update:bounds="updateBounds"
      @update:center="updateCenter" :options="{ attributionControl: false }" @ready="mapReady">
      <l-control-attribution position="topright"></l-control-attribution>
      <l-tile-layer :url="tileLayerUrl"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'></l-tile-layer>
      <l-marker :lat-lng="userLocation" :icon="userIcon"></l-marker>
      <l-marker v-for="point in filteredRecyclePoints" :key="point.serial" :lat-lng="[point.lat, point.lon]"
        :icon="getRecycleIcon(point)" :z-index-offset="getZIndexOffset(point)"
        @click="selectRecyclePoint(point)"></l-marker>
      <l-circle v-if="showCircle" :lat-lng="userLocation" :radius="showRecyclePointsDistance"
        :options="{ color: 'transparent', fillColor: '#E3681A', fillOpacity: 0.3 }"></l-circle>
      <l-control position="bottomright">
        <div style="">
          <div v-if="showMap" class="btn reset-location" :class="{ 'with-selected-point': selectedRecyclePoint }"
            @click="resetMapCenter">
            <img :src="iconUrl.svgSendHor" alt="icon">
          </div>

          <div v-if="selectedRecyclePoint" class="recycle-point-info">
            <div class="name">舊衣回收箱 {{ selectedRecyclePoint.serial }} 號</div>
            <div class="distance">
              <img :src="iconUrl.svgPin" alt="icon">
              <span>{{ calculateDistance(selectedRecyclePoint) }} m</span>
            </div>
            <div class="address">{{ selectedRecyclePoint.city + selectedRecyclePoint.area + selectedRecyclePoint.address
            }}</div>
            <div class="link btn" @click="linkToRecycle">
              <img :src="iconUrl.svgGoogleMap" alt="icon">
              <span>在 Goolge 地圖查看</span>
              <img :src="iconUrl.svgExternalLink" alt="icon">
            </div>
          </div>
        </div>

      </l-control>
    </l-map>
    <WcAlertModal v-if="showGPSAlert" @ok="showGPSAlert = false">
      <h2 class="text-center">無法取得位置</h2>
      <p class="text-center">
        請分享你的位置我們將自動幫你搜尋最近的回收箱
      </p>
    </WcAlertModal>
  </div>
</template>

<script>
import axios from 'axios';
import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LCircle, LControlAttribution, LControl } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { getDistance } from 'geolib';

import svgCurrentLandmark from "@/assets/icon/current-landmark.svg";
import svgLandmark from "@/assets/icon/landmark.svg";
import svgPin from "@/assets/icon/Pin.svg";
import svgGoogleMap from "@/assets/icon/google map.svg";
import svgExternalLink from "@/assets/icon/external-link.svg";
import svgSendHor from "@/assets/icon/Send_hor.svg";

import WcAlertModal from '@/components/commons/WcAlertModal.vue';

export default {
  name: 'RealTimeMap',
  data() {
    return {
      showMap: false,
      showCircle: true,
      zoom: 16,
      center: [0, 0], // Initial center, can be adjusted based on your needs
      tileLayerUrl: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
      userLocation: [0, 0],
      userIcon: L.divIcon({
        className: 'custom-icon',
        html: '<div class="base-circle"></div><div class="center-dot"></div>',
        iconSize: [144, 144],
        iconAnchor: [72, 72],
        popupAnchor: [0, -72],
      }),
      showRecyclePointsDistance: 500,
      recyclePoints: [],
      selectedRecyclePoint: null,
      recycleIcon: L.icon({
        iconUrl: svgLandmark,
        iconSize: [36, 36],
        iconAnchor: [18, 36],
      }),
      selectedRecycleIcon: L.icon({
        iconUrl: svgCurrentLandmark,
        iconSize: [48, 48],
        iconAnchor: [24, 48],
      }),
      watchId: null, // 用來存放 watchPosition 的 ID

      iconUrl: {
        svgPin: svgPin,
        svgGoogleMap: svgGoogleMap,
        svgExternalLink: svgExternalLink,
        svgSendHor: svgSendHor,
      },
      showGPSAlert: false,

      bounds: {
        north: 0,
        south: 0,
        east: 0,
        west: 0,
      }
    };
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LControlAttribution,
    LControl,
    WcAlertModal,
  },
  computed: {
    filteredRecyclePoints() {
      // 計算與 userLocation 的距離，僅保留小於 showRecyclePointsDistance 公尺的回收點
      // return this.recyclePoints.filter((point) =>
      //   getDistance(
      //     { latitude: this.userLocation[0], longitude: this.userLocation[1] },
      //     { latitude: point.lat, longitude: point.lon }
      //   ) < this.showRecyclePointsDistance || (this.selectedRecyclePoint !== null && this.selectedRecyclePoint.serial === point.serial)
      // );
      const leftBottom = L.latLng(this.bounds.south, this.bounds.west);
      const rightTop = L.latLng(this.bounds.north, this.bounds.east);
      const bounds = L.latLngBounds(leftBottom, rightTop);
      return this.recyclePoints.filter((point) => {
        return bounds.contains({
          lat: point.lat,
          lng: point.lon
        });
      });
    },
    calculateDistance() {
      return (point) => {
        if (this.userLocation && point.lat && point.lon) {
          const distance = getDistance(
            { latitude: this.userLocation[0], longitude: this.userLocation[1] },
            { latitude: point.lat, longitude: point.lon }
          );

          return distance;
        }

        return 0;
      };
    },
  },
  methods: {
    linkToRecycle() {
      const item = this.selectedRecyclePoint;
      // console.log(this.selectedRecyclePoint);
      window.open(`https://maps.google.com/?q=${item.lat},${item.lon}`, "_blank");
    },
    updateZoom(newZoom) {
      this.zoom = newZoom;
    },
    updateCenter(newCenter) {
      this.center = newCenter;
    },
    updateBounds(newBounds) {
      // console.log(newBounds);
      this.bounds.north = newBounds.getNorth();
      this.bounds.south = newBounds.getSouth();
      this.bounds.east = newBounds.getEast();
      this.bounds.west = newBounds.getWest();
    },
    viewChanged() {
    },
    resetMapCenter() {
      this.center = this.userLocation;
    },
    async initGeolocation() {
      try {
        if (navigator.geolocation) {
          await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((position) => {
              this.userLocation = [position.coords.latitude, position.coords.longitude];
              this.resetMapCenter();
              resolve();
            }, (error) => {
              reject(error);
            });

          });
          this.watchId = navigator.geolocation.watchPosition(
            (position) => {
              this.userLocation = [position.coords.latitude, position.coords.longitude];
              this.resetMapCenter();
            },
            (error) => {
              console.error(error);
            }
          );
          this.showMap = true;
        } else {
          console.error('Geolocation is not supported by this browser.');
          throw new Error('Geolocation is not supported by this browser.');
        }
      } catch (error) {
        this.showGPSAlert = true;
      }
    },
    async getRecyclePoints() {
      const apiUrl = 'https://walkingcloset.network/closet/api/opendata/clothRecycle';

      await axios.get(apiUrl)
        .then(response => {
          this.recyclePoints.splice(0, this.recyclePoints.length);
          for (const p of response.data.data) {
            this.recyclePoints.push(p);
          }
          // console.log(this.recyclePoints)
        })
        .catch(error => {
          console.error('Error fetching recycle points:', error);
        });
    },
    async initMap() {
      await this.initGeolocation();
      await this.getRecyclePoints();
      const nearestRecyclePoint = this.findNearestRecyclePoint();
      if (nearestRecyclePoint) {
        this.selectRecyclePoint(nearestRecyclePoint);
      }
    },
    selectRecyclePoint(point) {
      this.selectedRecyclePoint = point;
    },
    getRecycleIcon(point) {
      const isSelected = this.selectedRecyclePoint === point;
      return isSelected ? this.selectedRecycleIcon : this.recycleIcon;
    },
    getZIndexOffset(point) {
      return this.selectedRecyclePoint === point ? 1000 : 0;
    },
    findNearestRecyclePoint() {
      if (this.recyclePoints.length === 0) {
        return null;
      }

      let nearestPoint = this.recyclePoints[0];
      let nearestDistance = getDistance(
        { latitude: this.userLocation[0], longitude: this.userLocation[1] },
        { latitude: nearestPoint.lat, longitude: nearestPoint.lon }
      )

      for (const point of this.recyclePoints) {
        const distance = getDistance(
          { latitude: this.userLocation[0], longitude: this.userLocation[1] },
          { latitude: point.lat, longitude: point.lon }
        )

        if (distance < nearestDistance) {
          nearestPoint = point;
          nearestDistance = distance;
        }
      }

      return nearestPoint;
    },
    mapReady(map) {
      this.updateBounds(map.getBounds());
    },
  },
  beforeDestroy() {
    if (navigator.geolocation && this.watchId !== null) {
      navigator.geolocation.clearWatch(this.watchId);
    }
  },
  mounted() {
    this.initMap();
  }
}
</script>

<style lang="scss">
.real-time-map {
  position: relative;
  height: 100%;
}

.custom-icon {
  position: relative;
  width: 100%;
  height: 100%;
}

.base-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #E3681A;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.center-dot {
  width: 21.6px;
  height: 21.6px;
  border-radius: 50%;
  background: #E3681A;
  border: 3.6px solid white;
  position: absolute;
  top: calc(50% - 10.8px);
  left: calc(50% - 10.8px);
}

.recycle-point-info {
  position: relative;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.97);
  // width: 100%;
  width: 400px;

  padding: 24px 22px 39px;
  border-radius: 16px 16px 16px 16px;
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.08), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 400;

  @media (max-width: 768px) {
    width: 100vw;
    margin: -10px;
    border-radius: 16px 16px 0px 0px;

  }

  .name {
    color: #212429;
    margin-bottom: 4px;
    text-align: left;

    /* H3/子子標題 */
    font-family: Hiragino Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 100% */
  }

  .distance {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    span {
      color: #4D4D4D;

      /* Paragraphs/備註 */
      font-family: Hiragino Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 21px */
    }
  }

  .address {
    color: #4D4D4D;
    margin-bottom: 14px;
    text-align: left;

    /* Paragraphs/備註 */
    font-family: Hiragino Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 21px */
  }

  .link.btn {
    display: flex;
    height: 44px;
    padding: 8px 27px;
    justify-content: center;
    align-items: center;

    border-radius: 16px;
    border: 1px solid #E3861A;
    background: #FFF;

    span {
      color: #4D4D4D;
      text-align: center;

      /* Paragraphs/段落 */
      font-family: Hiragino Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      /* 24px */
    }

    :not(:last-child) {
      margin-right: 8px;
    }
  }
}

.btn.reset-location {
  // margin-left: auto;
  margin-bottom: 1rem;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 0;
  transform: translate(0, calc(-100% - 1rem));
  // bottom: 24px;
  // z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #FFF;
  width: 56px;
  height: 56px;
  border-radius: 100px;
  box-shadow: 0px 4px 30px 0px rgba(141, 141, 141, 0.36);

  &.with-selected-point {
    // bottom: 226px;
  }
}
</style>
